import { configureStore } from "@reduxjs/toolkit";
import inventoryReducer from "./inventory-slice";
import pageReducer from "./page-slice";

export default configureStore({
  reducer: {
    inventory: inventoryReducer,
    page: pageReducer,
  },
});
