import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Grid } from "@mui/material";
import BasePage from "./BasePage";
import { useDispatch, useSelector } from "react-redux";
import { saveDevice } from "../client/devices-client";
import { addDevice, updateDevice } from "../redux/inventory-slice";
import { useNavigate, useParams } from "react-router-dom";

const initialDevice = {
  deviceId: "",
  name: "",
  username: "",
  password: "",
  ip: "",
  port: "",
  channelPath: "",
  frameRate: 25,
  recordingIntervalSeconds: 60,
  retentionTimeInDays: 1,
};

function DevicePage() {
  const [device, setDevice] = useState(initialDevice);
  const navigate = useNavigate();
  const params = useParams();
  const { deviceId } = params;
  console.log(deviceId);
  const dispatch = useDispatch();
  const inventory = useSelector((state) => state.inventory.devices);
  console.log(inventory);

  useEffect(() => {
    console.log("use effect called");
    if (deviceId) {
      const existingDevice = inventory.find((d) => d.deviceId === deviceId);
      if (existingDevice) {
        setDevice(existingDevice);
      }
    } else {
      setDevice(initialDevice);
    }
  }, [deviceId, inventory, params]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDevice({ ...device, [name]: value });
  };

  const handleSubmit = () => {
    if (deviceId) {
      saveDevice(device)
        .then((res) => res.data)
        .then((data) => {
          console.log(data);
          dispatch(updateDevice(data));
          navigate(`/inventory`);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      saveDevice(device)
        .then((res) => res.data)
        .then((data) => {
          console.log(data);
          dispatch(addDevice(data));
          navigate(`/inventory`);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const isExistingDevice = () => {
    return !!deviceId;
  };

  return (
    <BasePage>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Camera ID"
              name="deviceId"
              disabled
              value={device.deviceId}
              onChange={handleChange}
              contentEditable={false}
              sx={{ display: isExistingDevice() ? "block" : "none" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Camera Name"
              name="name"
              value={device.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Username"
              name="username"
              value={device.username}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Password"
              name="password"
              value={device.password}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="IP"
              name="ip"
              value={device.ip}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Port"
              name="port"
              value={device.port}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Channel Path"
              name="channelPath"
              value={device.channelPath}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Frame Rate"
              name="frameRate"
              type="number"
              value={device.frameRate}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Partioning Interval (seconds)"
              name="recordingIntervalSeconds"
              type="number"
              value={device.recordingIntervalSeconds}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Recording Retention Time (days)" // Add the label for Retention Time
              name="retentionTimeInDays" // Add the name for Retention Time
              type="number"
              value={device.retentionTimeInDays} // Use the Retention Time value
              onChange={handleChange} // Handle changes to Retention Time
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Container>
    </BasePage>
  );
}

export default DevicePage;
