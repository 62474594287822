import { api } from "./axios-base";

export const getAllDevices = () => {
  return api.get("/inventory");
};

export const getDevice = (device_id) => {
  return api.get(`/inventory/${device_id}`);
};

export const saveDevice = (cameraConfig) => {
  return api.post("/inventory", cameraConfig);
};

export const removeDevice = (deviceId) => {
  console.log(deviceId);
  return api.delete(`/inventory/${deviceId}`);
};

export const startRecording = (deviceId) => {
  return api.post(`/live-feed/${deviceId}/record`);
};

export const stopRecording = (deviceId) => {
  return api.delete(`/live-feed/${deviceId}/record`);
};
