import React, { useState, useEffect } from "react";
import BasePage from "./BasePage";
import DeviceLiveFeed from "../components/DeviceLiveFeed";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { setLiveFeedColNumber } from "../redux/page-slice";

const LiveFeedPage = (props) => {
  const inventory = useSelector((state) => state.inventory.devices);
  const columnNumber = useSelector((state) => state.page.columnNumber);

  const generateGridColumns = () => {
    const columns = [];
    for (let i = 0; i < columnNumber; i++) {
      columns.push("1fr"); // Equal width columns
    }
    return columns.join(" "); // Join column widths as a string
  };

  return (
    <BasePage>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          spacing={0.2}
          sx={{
            display: "grid",
            gridTemplateColumns: generateGridColumns(),
          }}
        >
          {inventory.map((device) => (
            <Grid
              item
              key={device.deviceId}
              justifyContent={"center"}
              alignItems={"center"}
              display="flex"
              sx={
                {
                  // border: "solid 1px red",
                }
              }
            >
              <DeviceLiveFeed device={device} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </BasePage>
  );
};

export default LiveFeedPage;
