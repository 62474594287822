import { createSlice } from "@reduxjs/toolkit";

export const inventorySlice = createSlice({
  name: "inventory",
  initialState: {
    devices: [],
  },
  reducers: {
    addDevice: (state, action) => {
      state.devices.push(action.payload);
    },
    updateDevice: (state, action) => {
      console.log(action.payload);
      const index = state.devices.findIndex(
        (device) => device.deviceId === action.payload.deviceId
      );

      if (index !== -1) {
        state.devices[index] = { ...state.devices[index], ...action.payload };
      }
    },
    refreshDevices: (state, action) => {
      state.devices = action.payload;
    },
    deleteDevice: (state, action) => {
      state.devices = state.devices.filter((d) => d.deviceId != action.payload);
    },
  },
});

export const { addDevice, updateDevice, refreshDevices, deleteDevice } =
  inventorySlice.actions;

export default inventorySlice.reducer;
