import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import BasePage from "./BasePage";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // 3-dot menu icon
import { Link } from "react-router-dom";
import {
  getAllDevices,
  removeDevice,
  startRecording,
  stopRecording,
} from "../client/devices-client";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDevice,
  refreshDevices,
  updateDevice,
} from "../redux/inventory-slice";

function InventoryPage() {
  const dispatch = useDispatch();
  const inventory = useSelector((state) => state.inventory.devices);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);

  useEffect(() => {
    getAllDevices()
      .then((res) => res.data)
      .then((data) => dispatch(refreshDevices(data)));
  }, []);

  const getStatusIcon = (status) => {
    console.log("status printing");
    console.log(status);
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {status === "Recording" ? (
          <>
            <VideocamIcon sx={{ color: "green", verticalAlign: "middle" }} />
            <Typography variant="subtitle2" fontSize={12} color="textSecondary">
              Recording
            </Typography>
          </>
        ) : (
          <>
            <VideocamOffIcon
              sx={{ color: "darkred", verticalAlign: "middle" }}
            />
            <Typography variant="subtitle2" fontSize={10} color="textSecondary">
              Not Recording
            </Typography>
          </>
        )}
      </div>
    );
  };

  const handleMenuOpen = (event, deviceId) => {
    setAnchorEl(event.currentTarget);
    setSelectedDeviceId(deviceId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedDeviceId(null);
  };

  const handleDeleteDevice = (deviceId) => {
    dispatch(deleteDevice(deviceId));
    removeDevice(deviceId);
  };

  const handleStartRecording = (deviceId) => {
    startRecording(deviceId)
      .then((res) => res.data)
      .then((data) => dispatch(updateDevice(data)));
  };

  const handleStopRecording = (deviceId) => {
    stopRecording(deviceId)
      .then((res) => res.data)
      .then((data) => dispatch(updateDevice(data)));
  };

  console.log(inventory);
  return (
    <BasePage>
      <Container>
        <Grid container spacing={2}>
          {inventory.map((device) => (
            <Grid item xs={12} sm={12} md={6} key={device.deviceId}>
              <Card elevation={12}>
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center" }} mb={4}>
                    {getStatusIcon(device.status)}
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="h5"
                        component="div"
                        style={{
                          marginLeft: "8px",
                          // border: "solid 1px red",
                          textAlign: "center",
                        }}
                      >
                        {device.name}
                      </Typography>
                    </Box>
                    <IconButton
                      aria-label="menu"
                      aria-controls={`menu-${device.deviceId}`}
                      aria-haspopup="true"
                      onClick={(event) =>
                        handleMenuOpen(event, device.deviceId)
                      }
                      style={{ marginLeft: "auto" }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id={`menu-${device.id}`}
                      anchorEl={anchorEl}
                      open={
                        Boolean(anchorEl) &&
                        selectedDeviceId === device.deviceId
                      }
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        component={Link}
                        to={`/device/${device.deviceId}`}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleStartRecording(device.deviceId)}
                      >
                        Start Recording
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleStopRecording(device.deviceId)}
                      >
                        Stop Recording
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleDeleteDevice(device.deviceId)}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box marginBottom={1}>
                    <Typography variant="body2">IP: {device.ip}</Typography>
                  </Box>
                  <Box marginBottom={1}>
                    <Typography variant="body2">Port: {device.port}</Typography>
                  </Box>
                  <Box marginBottom={1}>
                    <Typography variant="body2">
                      Channel Path: {device.channelPath}
                    </Typography>
                  </Box>
                  <Box marginBottom={1}>
                    <Typography variant="body2">
                      Frame Rate: {device.frameRate}
                    </Typography>
                  </Box>
                  <Box marginBottom={1}>
                    <Typography variant="body2">
                      Recording Interval (Seconds):
                      {" " + device.recordingIntervalSeconds}
                    </Typography>
                  </Box>
                  <Box marginBottom={1}>
                    <Typography variant="body2">
                      Recording Retention (Days):
                      {" " + device.retentionTimeInDays}
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <Typography
                      variant="subtitle2"
                      fontSize={12}
                      color="textSecondary"
                    >
                      ID: {device.deviceId}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </BasePage>
  );
}

export default InventoryPage;
