import { createSlice } from "@reduxjs/toolkit";

export const pageSlice = createSlice({
  name: "page",
  initialState: {
    columnNumber: 2,
  },
  reducers: {
    setLiveFeedColNumber: (state, action) => {
      state.columnNumber = action.payload;
    },
  },
});

export const { setLiveFeedColNumber } = pageSlice.actions;

export default pageSlice.reducer;
