import axios from "axios";

const LOCAL_URL = "http://localhost:8000";

const PROD_URL = "http://3.10.235.197:8000";

export const BASE_URL = PROD_URL;

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
