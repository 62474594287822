import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { BASE_URL } from "../client/axios-base";

const DeviceLiveFeed = ({ device }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    loadStream(device);
  }, [device]);

  const loadStream = (device) => {
    videoRef.current.src = `${BASE_URL}/live-feed/${device.deviceId}`;
  };

  return (
    <img
      ref={videoRef}
      style={{
        maxWidth: "100%",
        width: "100%",
        height: "auto",
        // border: "solid 1px red",
      }}
      alt={`Live Feed ${device.deviceId}`}
    />
  );
};

export default DeviceLiveFeed;
