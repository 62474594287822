import BasePage from "./BasePage";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react"; // Import useState to manage state
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const LoginPage = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (value) => {
    setUsername(value);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
  };

  const handleLogin = (e) => {
    // Handle the login action
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh" // Make the container full viewport height
    >
      <Typography variant="h4" gutterBottom>
        Dincer Home
      </Typography>
      <TextField
        label="Username"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(e) => handleUsernameChange(e.target.value)}
        value={username}
        style={{ maxWidth: "300px" }} // Limit the width
      />
      <TextField
        label="Password"
        variant="outlined"
        fullWidth
        margin="normal"
        type="password"
        onChange={(e) => handlePasswordChange(e.target.value)}
        value={password}
        style={{ maxWidth: "300px" }} // Limit the width
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleLogin}
        style={{ maxWidth: "300px" }} // Limit the width
      >
        Login
      </Button>
    </Box>
  );
};

export default LoginPage;
