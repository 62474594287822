import { Box } from "@mui/material";
import NavBar from "../components/nav/NavBar";

const BasePage = (props) => {
  const { children, sx } = props;
  return (
    <NavBar>
      <Box {...props}>{children}</Box>
    </NavBar>
  );
};

export default BasePage;
