import { createBrowserRouter } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import HomePage from "../pages/HomePage";
import DevicePage from "../pages/DevicePage";
import InventoryPage from "../pages/InventoryPage";
import LiveFeedPage from "../pages/LiveFeedPage";

const ROUTES = [
  {
    path: "/",
    element: <LiveFeedPage />,
  },
  {
    path: "/live-feed",
    element: <LiveFeedPage />,
  },
  {
    path: "/inventory",
    element: <InventoryPage />,
  },
  {
    path: "/device",
    element: <DevicePage />,
  },
  {
    path: "/device/:deviceId",
    element: <DevicePage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
];

export const ROUTER = createBrowserRouter(ROUTES);
